html { 
  background: #f8f9fa;
  height: 100%;
  overflow: hidden;
  min-width: 380px;
  width: 100%;
}
body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: linear-gradient(0deg, #55ddff 0%, #dd55ff 85%);
  font-family: Helvetica, sans-serif;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

nav {
  min-width: 380px;
}
main {
  position: fixed;
  top: 62px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
}

.form-control {
  /* This is necessary to remove whitespace around the middle inputs in a group. */
  background-clip: inherit;
}

.login {
  min-height: 60px;
}

.header-l {
  left: 0;
  position: sticky;
  z-index: 50;
}
.header-r {
  position: sticky;
  right: 0;
  z-index: 50;
}
.center {
  left: 50%;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.coursetitle {
  border-radius: unset;
}
.unitcard {
  position: relative;
}
.unit.count {
  right: 8px;
  position: absolute;
  top: 8px;
  z-index: 1;
}
.lessonback {
  cursor: pointer;
}

.mungwrap {
  height: 100%;
}
.mungboard {
  width: 100%;
  overflow-y: scroll;
  padding: 0;
  padding-bottom: 80px;
}
.mungboard .bg {
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: -1000;
}

.mungboard table {
  border-collapse: collapse;
  display: table;
  padding: 0;
  margin-top: 4px;
  width: 100%;
}

.mungrow {
  display: flex;
  position: relative;
  margin-top: 2px;
}
.mungrow .alert {
  margin-top: 2px;
  width: 100vw;
}
.mungrow td {
  padding: 0;
  width: 80px;
}
.mungrow .trackwrap {
  width: 0;
  z-index: -1;
}
.mungrow .space {
  width: 80px;
}

.mungop{
  align-items: center;
  background: white;
  border: none;
  border-radius: .25em;
  display: flex;
  cursor: pointer;
  height: 66px;
  position: relative;
  width: 66px;
}

.opwrap.selected {
  -webkit-animation: glow 1.5s infinite;
  animation: glow 1.5s infinite;
}
@-webkit-keyframes glow { 
  0% {
    -webkit-filter: brightness(0.5);
    filter: brightness(0.5);
  }
  50% {
    -webkit-filter: brightness(1.5);
    filter: brightness(1.5);
  }
  100% {
    -webkit-filter: brightness(0.5);
    filter: brightness(0.5);
  }
}
@keyframes glow {
  0% {
    -webkit-filter: brightness(0.5);
    filter: brightness(0.5);
  }
  50% {
    -webkit-filter: brightness(1.5);
    filter: brightness(1.5);
  }
  100% {
    -webkit-filter: brightness(0.5);
    filter: brightness(0.5);
  }
}

.mungop .badge{
  left: 2px;
  position: absolute;
  top: 44px;
}
.badge{
  font-size: small;
}

.mungin{
  align-items: center;
  background: white;
  display: flex;
  height: 66px;
  position: relative;
  width: 35px;
}
.mungin::after {
  border: 24px solid;
  border-color: white transparent transparent white;
  border-radius: .25rem;
  content: '';
  left: 8px;
  position: absolute;
  transform: rotate(135deg);
}
.mungin .name {
  margin-left: -5px;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.mungin .badge{
  left: 1px;
  position: absolute;
  top: 43px;
  z-index: 1;
}
.mungin .badge.type{
  font-size: x-small;
  top: 1px;
}

.mungout{
  align-items: center;
  background: white;
  display: flex;
  float: right;
  height: 66px;
  padding: revert;
  position: relative;
  width: 35px; 
}
.mungout::before {
  border: 24px solid;
  border-color: white transparent transparent white;
  border-radius: .25rem;
  content: '';
  position: absolute;
  right: 8px;
  transform: rotate(-45deg);
}
.mungout .name {
  margin-left: -10px;
  width: 100%;
  z-index: 1;
}
.mungout .badge{
  position: absolute;
  right: 1px;
  top: 43px;
  z-index: 1;
}
.mungout .badge.type{
  font-size: x-small;
  top: 1px;
}

.mungmenu {
  margin-top: -4px;
  min-width: 380px;
}
.menu-toggle {
  margin-top: -46px;
  position: absolute;
  width: 100%;
  z-index: 100;
}
.mod.btn {
  font-size: small;
  padding: revert;
}
.mod.head.btn {
  margin-top: -3px;
}
.flair {
  font-weight: bold;
  -webkit-animation-name: flairAnim;
          animation-name: flairAnim;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.flair-group {
  display: flex;
  overflow-x: auto;
}
.flair-pick {
  border: solid 1px;
  border-radius: .25rem;
  height: 32px;
  margin-bottom: 0;
  margin-right: .25em;
  padding: 0;
  width: 32px;
}
.flair-pick.bg {
  margin-bottom: .5em;
}
.flair-pick-part {
  height: 15px;
  width: 15px;
  float: left;
}
.flair-pick-part.col1 {
  border-top-left-radius: .2rem;
}
.flair-pick-part.col2 {
  border-top-right-radius: .2rem;
}
.flair-pick-part.col3 {
  border-bottom-left-radius: .2rem;
}
.flair-pick-part.col4 {
  border-bottom-right-radius: .2rem;
}

.mungdeck {
  height: 30vh;
  min-width: 380px;
}
.mungcards {
  display: flex;
  overflow-x: auto;
}
.mungcard {
  height: 64px;
  width: 64px;
}

/* arrows: */
.arrowAnim {
  display: flex;
  position: relative;
  width: 97%;
}

.track {
  width: 80px;
  height: 48px;
  border: 6px solid;
  border-left: none;
  border-right: none;
  transform: translate(0px, 8px);
}
.track.end {
  width: 50px;
}

.arrow {
  border: 6px solid;
  border-color: grey transparent transparent grey;
  height: 24px;
  position: absolute;
  top: 6px;
  transform: rotate(135deg);
  width: 24px;
}

.arrowSliding {
  -webkit-animation: slide 3s linear infinite; 
          animation: slide 3s linear infinite;
}


.delay1 {
  -webkit-animation-delay: -2s; 
          animation-delay: -2s;
}
.delay2{
  -webkit-animation-delay: -1.75s; 
          animation-delay: -1.75s;
}
.delay3 {
  -webkit-animation-delay: -1.5s; 
          animation-delay: -1.5s;
}
.delay4 {
  -webkit-animation-delay: -1.25s; 
          animation-delay: -1.25s;
}
.delay5 {
  -webkit-animation-delay: -1s; 
          animation-delay: -1s;
}
.delay6 {
  -webkit-animation-delay: -0.75s; 
          animation-delay: -0.75s;
}
.delay7 {
  -webkit-animation-delay: -0.5s; 
          animation-delay: -0.5s;
}
.delay8 {
  -webkit-animation-delay: -0.25s; 
          animation-delay: -0.25s;
}

@-webkit-keyframes slide {
    0% { opacity: 0.1; transform: translateX(0px); }
   50% { opacity: 0.9; transform: translateX(40px); }
  100% { opacity: 0.1; transform: translateX(80px); }
}
@keyframes slide {
    0% { opacity: 0.1; transform: translateX(0px); }
   50% { opacity: 0.9;; transform: translateX(40px); }
  100% { opacity: 0.1; transform: translateX(80px); }
}

/* end arrows */
/* flair: */

@-webkit-keyframes flairAnim {
  0% { color: #0088ff; }
  50% { color: #ff00c8; }
  100% { color: #0088ff; }
}

@keyframes flairAnim {
  0% { color: #0088ff; }
  50% { color: #ff00c8; }
  100% { color: #0088ff; }
}

.fg.col1{
  background: #cccccc;
}
.fg.col1::after{
  border-color: #cccccc transparent transparent #cccccc;
}
.fg.col2{
  background: #999999;
}
.fg.col2::after{
  border-color: #999999 transparent transparent #999999;
}
.fg.col3{
  background: #666666;
}
.fg.col3::after{
  border-color: #666666 transparent transparent #666666;
}
.fg.col4{
  background: #333333;
  color: white;
}
.fg.col4::after{
  border-color: #333333 transparent transparent #333333;
}

/* end flair */
/* icons: */

.material-icons-outlined.md-18,
.material-icons-round.md-18,
.material-icons.md-18 { font-size: 18px; }
.material-icons-outlined.md-24,
.material-icons-round.md-24,
.material-icons.md-24 { font-size: 24px; }
.material-icons-outlined.md-36,
.material-icons-round.md-36,
.material-icons.md-36 { font-size: 36px; }
.material-icons-outlined.md-48,
.material-icons-round.md-48,
.material-icons.md-48 { font-size: 48px; }
/* end icons */

.wait-screen {
  background: #000;
  height:     100%;
  left:       0;
  opacity:    0.7;
  position:   absolute;
  top:        0;
  width:      100%;
  z-index:    10000;
}
.screen {
  background: #000;
  height:     100%;
  left:       0;
  opacity:    0.5;
  position:   absolute;
  top:        0;
  width:      100%;
  z-index:    1000;
}
.focus {
  position:   absolute;
  z-index:    1100;
}